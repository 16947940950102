<template>
  <div class="page">
    <section
      v-if="errored"
      style="
        height: 500px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        background-color: #757575;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        color: white;
        align-items: center;
      "
    >
      <p style="width: 100%; text-align: center">
        We're sorry, we're not able to retrieve this information at the moment,
        please try back later
      </p>
    </section>

    <section v-else>
      <div v-if="loading">
        <div
          class="loading loading-lg"
          style="
            height: 100vh;
            display: block;
            position: relative;
            background-color: #757575;
          "
        ></div>
      </div>

      <div v-if="info">
        <picture
          style="
            height: 500px;
            background-color: #757575;
            margin-bottom: 0px;
            background-position: center center;
            position: relative;
            display: block;
          "
        >
          <source
            media="(min-width: 1280px)"
            v-bind:srcset="baseurl + info.page_placeholder_image_web.path"
          />
          <source
            media="(min-width: 840px)"
            v-bind:srcset="baseurl + info.page_placeholder_image_tab.path"
          />
          <source
            media="(min-width: 600px)"
            v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path"
          />
          <img
            v-bind:src="baseurl + info.page_placeholder_image_mobile.path"
            v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path"
            v-bind:data-srcset="
              baseurl + info.page_placeholder_image_mobile.path
            "
            v-bind:alt="info.page_title_ar"
            v-bind:title="info.page_title_ar"
            v-bind:aria-label="info.page_title_ar"
            style="width: 100%; height: 500px; object-fit: cover"
          />

          <div
            style="
              width: 90%;
              text-align: center;
              color: #ffffff;
              margin-left: 5%;
              margin-right: 5%;
              position: absolute;
              display: block;
              top: 50%;
              margin: auto;
              left: 0;
              right: 0;
            "
          >
            <h1
              style="
                margin-bottom: 0px;
                padding-top: 20px;
                font-weight: 700;
                line-height: 1;
                text-shadow: 5px 5px 20px #000000;
              "
            >
              {{ info.page_title_ar }}
            </h1>
          </div>
        </picture>

        <div class="container grid-xl" v-if="mediax">
          <div class="columns">
            <h1
              style="
                font-weight: 900;
                text-align: center;
                width: 80%;
                margin-left: 10%;
                margin-right: 10%;
                margin-bottom: 40px;
                margin-top: 40px;
              "
            >
              <div
                v-if="mediax.media_title_ar && mediax.media_title_ar.length > 1"
                style="direction: rtl"
              >
                {{ mediax.media_title_ar }}
              </div>
              <div v-else>
                {{ mediax.media_title }}
              </div>
            </h1>

            <picture class="MediaHolderImage">
              <source
                style="width: 100%"
                media="(min-width: 1280px)"
                v-bind:srcset="baseurl + mediax.media_place_holder_image.path"
              />
              <source
                style="width: 100%"
                media="(min-width: 840px)"
                v-bind:srcset="
                  baseurl + mediax.media_place_holder_image_tab.path
                "
              />
              <source
                style="width: 100%"
                media="(min-width: 600px)"
                v-bind:srcset="
                  baseurl + mediax.media_place_holder_image_mobile.path
                "
              />
              <img
                v-bind:src="
                  baseurl + mediax.media_place_holder_image_mobile.path
                "
                v-bind:srcset="
                  baseurl + mediax.media_place_holder_image_mobile.path
                "
                v-bind:data-srcset="
                  baseurl + mediax.media_place_holder_image_mobile.path
                "
                v-bind:alt="mediax.media_title"
                v-bind:title="mediax.media_title"
                v-bind:aria-label="mediax.media_title"
                style="width: 100%; height: auto; object-fit: cover"
              />
            </picture>

            <div
              v-if="mediax.media_title_ar && mediax.media_title_ar.length > 1"
              style="direction: rtl"
            >
              <div
                style="margin-top: 20px; text-align: right; direction: rtl"
                v-html="mediax.media_content_ar"
              ></div>
            </div>
            <div v-else>
              <div
                style="margin-top: 20px; text-align: left"
                v-html="mediax.media_content"
              ></div>
            </div>

            <div style="margin-top: 20px; margin-bottom: 20px; width: 100%">
              <router-link class="btn" to="/ar/about/media">عودة</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <enfooter></enfooter>
  </div>
</template>

<script>
import enfooter from "@/components/enfooter.vue";
import axios from "axios";

export default {
  name: "mediaid",

  data() {
    return {
      info: null,
      mediax: null,
      loading: true,
      errored: false,
      baseurl: "https://zubaircorp.com",
    };
  },

  components: {
    enfooter,
  },

  mounted() {
    axios
      .get(
        this.baseurl +
          "/cms/api/singletons/get/Media?token=72d0a17a1cfa0d99e532c39232bdd5"
      )
      .then((response) => {
        this.info = response.data;
      })
      .catch((error) => {
        console.log(error);
        this.errored = true;
      })
      .finally(() => (this.loading = false));

    axios
      .get(
        this.baseurl +
          "/cms/api/collections/entry/media/" +
          this.$route.params.mediaid
      )
      .then((response) => {
        this.mediax = response.data;
        //console.log(response);
      });
  },
};
</script>

<style>
p {
  margin: 0;
}
picture.MediaHolderImage {
  width: auto;
  margin-bottom: 20px;
  margin: auto;
}
</style>