<template>
    <div class="page">


        <section v-if="errored" style="height: 500px;display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
    background-color: #757575;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
    color: white;
	align-items: center;">
            <p style="width: 100%; text-align: center;">We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
        </section>

        <section v-else>
            <div v-if="loading">
                <div class="loading loading-lg" style="height: 100vh;display: block;position: relative;background-color: #757575;"></div>
            </div>

            <div v-if="info">



                <picture style="height: 500px;background-color: #757575;margin-bottom: 20px;
        background-position: center center;
        position: relative;display: block;">
                    <source media="(min-width: 1280px)" v-bind:srcset="baseurl + info.page_placeholder_image_web.path">
                    <source media="(min-width: 840px)" v-bind:srcset="baseurl + info.page_placeholder_image_tab.path">
                    <source media="(min-width: 600px)" v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path">
                    <img v-bind:src="baseurl + info.page_placeholder_image_mobile.path"
                         v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path"
                         v-bind:data-srcset="baseurl + info.page_placeholder_image_mobile.path"
                         v-bind:alt="info.page_title"
                         v-bind:title="info.page_title"
                         v-bind:aria-label="info.page_title"
                         style="width:100%;height: 500px;object-fit: cover;">

                    <div style="width: 90%;
    text-align: center;
    color: #ffffff;
    margin-left: 5%;
    margin-right: 5%;
    position: absolute;
    display: block;
    top: 50%;
    margin: auto;
    left: 0;
    right: 0;">
                        <h1 style="margin-bottom: 0px;
    padding-top: 20px;
    font-weight: 700;
    line-height: 1;
    text-shadow: 5px 5px 20px #000000;">
                            {{ info.page_title }}
                        </h1>
                    </div>

                </picture>


                <div class="container grid-xl" style="margin-top: 30px;margin-bottom: 30px;">
                    <div class="columns">
                        <div v-html="info.page_content"></div>
                    </div>
                </div>


            </div>

        </section>



                 <section v-if="errored1" class="container grid-xl" hidden>
                    <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
                </section>

                <section v-else>
                    <div v-if="loading1">
                        <div class="loading loading-lg"></div>
                    </div>

                    <div v-else style="margin-bottom: 40px;">

                    <div class="container grid-xl" style="margin-bottom:30px;padding-left: 20px;
    padding-right: 20px;">
                    <div class="columns">
                    <h2 style="font-weight: 700;display: block;padding-left: 10px;">Videos</h2>
                    </div>
                    </div>


                        <div class="container grid-xl" v-if="videos" style="padding-left: 20px;
    padding-right: 20px;">
                            <div class="columns">                            
                                <div class="column col-3 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3" v-for="id in videos" style="margin-bottom: 15px;">


                                            <a target="_blank" v-bind:href="'https://www.youtube.com/watch?v=' + id.id.videoId">
                                                        <img v-bind:src="id.snippet.thumbnails.medium.url"
                                        v-bind:srcset="id.snippet.thumbnails.medium.url"
                                        v-bind:data-srcset="id.snippet.thumbnails.medium.url"
                                        v-bind:alt="id.snippet.title"
                                        v-bind:title="id.snippet.title"
                                        v-bind:aria-label="id.snippet.title"
                                        style="width:100%;height: 150px;object-fit: cover;">
                                            </a>
                                           
                                   

                 
                                </div>
                                <div class="column col-3"><a class="btn" href="https://www.youtube.com/channel/UCuqxLXckA0HAXP4d7x5Q12Q/videos" target="_blank" title="More Videos">More Videos</a></div>
                                
                            </div>
                        </div>
                        

                    </div>

                </section>
               

                

                <section v-if="errored2" class="container grid-xl">
                    <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
                </section>

                <section v-else>
                    <div v-if="loading2">
                        <div class="loading loading-lg"></div>
                    </div>

                    <div v-else style="margin-bottom: 40px;">

                    <div class="container grid-xl" style="margin-bottom:30px;padding-left: 20px;
    padding-right: 20px;">
                    <div class="columns">
                    <h2 style="font-weight: 700;display: block;padding-left: 10px;">What`s New</h2>
                    </div>
                    </div>

                   

                        <div class="container grid-xl" style="padding-left: 20px;
    padding-right: 20px;">
                            <div class="columns">
                                
                                <div class="column col-3 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-3"  v-for="item in pageOfItems" :key="item._id" style="margin-bottom: 15px;">


                                    <div class="card">
                                        <div class="card-image">
                                            <router-link :to="'/en/about/media/id/' + item._id">
                                                <img v-bind:alt="item.media_title" v-bind:src="baseurl + item.media_place_holder_image_mobile.path" class="img-responsive media">
                                            </router-link>
                                        </div>
                                        <div class="card-header">
                                            <div class="card-title h5" style="font-size: 15px;margin: 0;">
                                                {{item.media_title.substring(0,30)}} ...
                                            </div>
                                        </div>
                                        <div style="font-size: 13px;margin: 0;" class="card-body" v-html="item.media_content.substring(0,80)"></div>
                                        <div class="card-footer" style="padding-top: 0;">
                                            <div style="font-size: 12px;margin-top: 12px;margin: 0;">{{item.media_date}}</div>
                                        </div>
                                    </div>


                                </div>
                            </div>



                        </div>
                        <div class="container grid-xl">
                            <jw-pagination :items="exampleItems" @changePage="onChangePage"></jw-pagination>
                        </div>

                    </div>

                </section>


                 <section v-if="errored2" class="container grid-xl">
                    <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
                </section>

                <section v-else>
                    <div v-if="loading2">
                        <div class="loading loading-lg"></div>
                    </div>

                    <div v-else style="margin-bottom: 40px;">

                    <div class="container grid-xl" style="margin-bottom:30px;margin-top:50px;">
                    <div class="columns">
                    <h2 style="font-weight: 700;display: block;padding-left: 10px;">Media Kit</h2>
                    </div>
                    </div>


                        <div class="container grid-xl" v-if="kit">
                            <div class="columns">                            
                                <div class="column col-7 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7" style="margin-bottom: 15px;">
                                        <div style="margin-bottom:30px;" v-html="kit.Media_Kit_about_Zubair_Corp"></div> 
                                        <h3><a target="_blank" class="btn" v-bind:href="baseurl + '/' +kit.Media_Kit_Zubair_Corp_Logo" download>Download Zubair Corporation Logo</a></h3>          
                                </div>
                                <div class="column col-5 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-5" style="margin-bottom: 15px;">
                                               <img v-bind:src="baseurl + kit.Media_Kit_Head_Office_photo.path"
                         v-bind:srcset="baseurl + kit.Media_Kit_Head_Office_photo.path"
                         v-bind:data-srcset="baseurl + kit.Media_Kit_Head_Office_photo.path"
                         v-bind:alt="kit.page_title"
                         v-bind:title="kit.page_title"
                         v-bind:aria-label="kit.page_title"
                         style="width:100%;height: auto;object-fit: cover;"> 
                                </div>
                                
                            </div>
                        </div>
                        

                    </div>

                </section>

        <enfooter></enfooter>

    </div>

</template>


<script>

    import enfooter from '@/components/enfooter.vue'
    import axios from 'axios'


    export default {

        name: 'media',
        data () {
            return {
                info: null,
                media: null,
                loading: true,
                errored: false,
                loading2: true,
                errored2: false,

               

                baseurl: 'https://zubaircorp.com',

                exampleItems:[],
                pageOfItems: [],

               
                videos: null,
                loading1: true,
                errored1: false,

                kit: null,
                loading2: true,
                errored2: false,

            }

        },



        methods: {
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },    

        },

        components: {
            enfooter,

        },


        mounted () {

            axios
                .get( this.baseurl + '/cms/api/singletons/get/Media?token=72d0a17a1cfa0d99e532c39232bdd5')
                .then(response => {
                    this.info = response.data
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                .finally(() => this.loading = false)



            axios
                    //.get( this.baseurl + '/cms/api/collections/get/media?token=72d0a17a1cfa0d99e532c39232bdd5&sort[media_date]=-1&limit=8&skip=0&page=1')
                    .get( this.baseurl + '/cms/api/collections/get/media/?token=72d0a17a1cfa0d99e532c39232bdd5&sort[media_date]=-1')
                .then(response => {
                    this.exampleItems = response.data.entries
                    //console.log(response)
                })
                .catch(error => {
                    console.log(error)
                    this.errored2 = true
                })
                .finally(() => this.loading2 = false)


                
                 axios
                    .get('https://www.googleapis.com/youtube/v3/search?key=AIzaSyDZPhUGG84H1JBam9w0GBjVz6xg6jVujT4&channelId=UCuqxLXckA0HAXP4d7x5Q12Q&part=snippet,id&order=date&maxResults=8')
                .then(response => {
                    this.videos = response.data.items
                    //console.log(response.data.items)
                })
                .catch(error => {
                    console.log(error)
                    this.errored1 = true
                })
                .finally(() => this.loading1 = false)


                 axios
                .get( this.baseurl + '/cms/api/singletons/get/Media?token=72d0a17a1cfa0d99e532c39232bdd5')
                .then(response => {
                    this.kit = response.data
                })
                .catch(error => {
                    console.log(error)
                    this.errored2 = true
                })
                .finally(() => this.loading2 = false)



        },




    }
</script>

<style>
    img.img-responsive.media {
        height: 150px;
        width: 100%;
        object-fit: cover;
        background-position: center center;
    }
    .card {
        height: 320px;
    }

div#YouTuber iframe {
    width: 100%;
    height: 500px;
}
div#YouTuber {
    width: 100%;
    margin-bottom: 30px;
}


 @media only screen and (max-width: 960px)
  {

    .card {
        height: 310px;
    }



  }

</style>