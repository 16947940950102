<template>
  <div class="page">


    <section v-if="errored" style="height: 500px;display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
    background-color: #757575;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
    color: white;
	align-items: center;">
      <p style="width: 100%; text-align: center;">We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>

    <section v-else>
      <div v-if="loading">
        <div class="loading loading-lg" style="height: 100vh;display: block;position: relative;background-color: #757575;"></div>
      </div>

      <div v-if="info">



        <picture style="height: 500px;background-color: #757575;margin-bottom: 20px;
        background-position: center center;
        position: relative;display: block;">
          <source media="(min-width: 1280px)" v-bind:srcset="baseurl + info.page_placeholder_image_web.path">
          <source media="(min-width: 840px)" v-bind:srcset="baseurl + info.page_placeholder_image_tab.path">
          <source media="(min-width: 600px)" v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path">
          <img v-bind:src="baseurl + info.page_placeholder_image_mobile.path"
               v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path"
               v-bind:data-srcset="baseurl + info.page_placeholder_image_mobile.path"
               v-bind:alt="info.page_title_ar"
               v-bind:title="info.page_title_ar"
               v-bind:aria-label="info.page_title_ar"
               style="width:100%;height: 500px;object-fit: cover;">

          <div style="width: 90%;
    text-align: center;
    color: #ffffff;
    margin-left: 5%;
    margin-right: 5%;
    position: absolute;
    display: block;
    top: 50%;
    margin: auto;
    left: 0;
    right: 0;">
            <h1 style="margin-bottom: 0px;
    padding-top: 20px;
    font-weight: 700;
    line-height: 1;
    text-shadow: 5px 5px 20px #000000;">
              {{ info.page_title_ar }}
            </h1>
          </div>

        </picture>


        <div class="container grid-xl">
          <div class="columns">


            <div style="width: 100%;margin-top: 30px;margin-bottom: 30px;" v-html="info.page_content_ar"></div>

            <div class="column col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">


              <div class="columns">


              <div class="column col-3 col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style="margin-bottom: 30px;">

              <select class="form-select" v-model="positions" required>

              <option value="" disabled selected hidden>Available Positions</option>

              <option v-for="value in info2">
              {{ value.value }}
              </option>   

              </select>

              </div>         



                <div class="column col-3 col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style="margin-bottom: 10px;">
                <!-- form input control -->
                <div class="form-group">
                <input aria-label="الاسم الأول" v-model="firstname" class="form-input" type="text" id="input-firstname" placeholder="الاسم الأول">
                </div>
                </div>

                 <div class="column col-3 col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style="margin-bottom: 10px;">
                <!-- form input control -->
                <div class="form-group">
                <input aria-label="اسم العائلة" v-model="lastname" class="form-input" type="text" id="input-lastname" placeholder="اسم العائلة">
                </div>
                </div>

                 <div class="column col-3 col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style="margin-bottom: 10px;">
                <!-- form input control -->
                <div class="form-group">
                <input aria-label="البريد الإلكتروني" v-model="email" class="form-input" type="email" id="input-email" placeholder="البريد الإلكتروني">
                </div>
                </div>

                 <div class="column col-3 col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style="margin-bottom: 10px;">
                <!-- form input control -->
                <div class="form-group">
                <input aria-label="الهاتف" v-model="phone" class="form-input" type="email" id="input-phone" placeholder="الهاتف" :maxlength="max">
                </div>
                </div>

                <div class="column col-4 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" style="margin-bottom: 20px;margin-top: 0px;">
                <!-- form input control -->
                <div class="form-group">
                <input   aria-label="Attach CV" placeholder="Attach CV" type="file" id="file" ref="file" v-on:change="handleFileUpload()" required/>
                <small>ارفق السيرة الذاتية والطلب بصيغة ملف PDF</small>
                </div>
                </div>

                <div class="column col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom: 10px;">
                <!-- form input control -->
                <div class="form-group">

                   <div  v-if="firstname === ''"><button class="btn" v-on:click="submit()" disabled>ارسال</button></div>
                            <div v-else-if="lastname === ''"><button class="btn" v-on:click="submit()" disabled>ارسال</button></div>
                            <div v-else-if="email === ''"><button class="btn" v-on:click="submit()" disabled>ارسال</button></div>
                            <div v-else-if="phone === ''"></div>
                            <div v-else>
                                <button class="btn" v-on:click="submitFile()">ارسال</button>
                            </div>


                
                </div>
                </div>


                <div class="column col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom: 10px;">

                <div v-if="!isHidden" class="loading loading-lg"></div>  
         
                </div>


                 <div class="column col-6 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style="margin-bottom: 10px;margin:auto;">

                <div style="width: 100%;text-align: center;font-weight: 400;font-size: 16px;margin-top: 20px;margin-bottom: 20px;" v-html="error"></div>
         
                </div>


              </div>









              <hr style="margin-bottom: 20px;margin-top: 20px;color: #e6e6e6;border: 0.5px solid;">

              <div><a style="font-weight: 500;text-transform: uppercase;" href="https://jobs.zubaircorp.com/"  target="_blank" alt="وظائف مؤسسة الزبير ">وظائف مؤسسة الزبير  <svg  width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 12V6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H12M8.11111 12H12M12 12V15.8889M12 12L5 19" stroke="#464455" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a></div>

            </div>



          </div>
        </div>






      </div>

    </section>





    <enfooter></enfooter>

  </div>

</template>

<script>






  import enfooter from '@/components/enfooter.vue'
  import axios from 'axios'

  export default {
    name: 'work-with-us',

    data () {
      return {
        info: null,
        info2: null,


        loading: true,
        errored: false,
        baseurl: 'https://zubaircorp.com',

        error: null,
        isHidden: true,


        max: 8,

        file: [],
        firstname:'',
        lastname:'',
        email:'',
        phone:'',
        positions:'عام'

      }
    },

 methods: {
      /*
        Submits the file to the server
      */
      submitFile(){
            
            this.isHidden = false;
            let formData = new FormData();

            formData.append('files[]', this.file);

            formData.append('meta[firstname]', this.firstname);
            formData.append('meta[lastname]', this.lastname);
            formData.append('meta[email]', this.email);
            formData.append('meta[phone]', this.phone);
            formData.append('meta[positions]', this.positions);


            formData.append('meta[folder]', '60263b81306561880700004f');

            this.isHidden = false;
                axios.post( this.baseurl + '/cms/api/cockpit/addAssets?token=72d0a17a1cfa0d99e532c39232bdd5',formData,
            {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
            }).then((response) => {
                    //console.log(response);
                    this.isHidden = true;
                    this.error = "<div class=\"toast toast-primary\">\n" +
                        "  شكراً على تقديم طلب التوظيف، سنتواصل معك بالتأكيد حال توفر شاغر مناسب.  \n" +
                        "</div>";
                    this.firstname='';
                    this.lastname='';
                    this.email='';
                    this.phone='';
                    this.file='';

                }).catch((error) => {
                   this.isHidden = false;

                    //console.log(error);
                    this.error = "<div class=\"toast toast-error\">\n" +
                        "  يرجى التأكد من البيانات المدخلة. لم يتم إرسال المعلومات.  \n" +
                        "</div>";
                }).then(function () {

                });



                

  
          




                
      },

      handleFileUpload(){
        this.file = this.$refs.file.files[0];
      }
    },

    components: {
      enfooter
    },

    mounted () {

      axios
              .get( this.baseurl + '/cms/api/singletons/get/Workwithus?token=72d0a17a1cfa0d99e532c39232bdd5')
              .then(response => {
                this.info = response.data
              })
              .catch(error => {
                console.log(error)
                this.errored = true
              })
              .finally(() => this.loading = false)

axios
  .get(this.baseurl + '/cms/api/singletons/get/Workwithus?token=72d0a17a1cfa0d99e532c39232bdd5')
   .then(response2 => {
                this.info2 = response2.data.available_positions_ar
              })



    },


    

  }


  
</script>

<style>
input#file {
    display: block;
}
</style>
