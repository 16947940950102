<template>
  <div class="home" v-if="info">



    <section v-if="errored" style="height: 100vh;display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
    background-color: #757575;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
    color: white;
	align-items: center;">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>

    <section v-else>
      <div v-if="loading">
        <div class="loading loading-lg" style="height: 100vh;display: block;position: relative;background-color: #757575;"></div>
      </div>

      <div v-else >


    <div v-if="window.width > '840'">  
        <splide :options="options">
        <splide-slide style="background-size: cover;"  v-for="entries in info.entries" :key="entries.src" v-bind:style="{ backgroundImage: 'url(' + baseurl + entries.block_a_image_web.path + ')' }">

         <div id="SliderHolder_ar" class="container grid-xl">
            <div class="columns" v-bind:style="{color: entries.block_a_color }">

              <div style="width: 100%;"><h2 style="font-weight: 600;line-height: 1;">{{entries.block_a_title_ar}}</h2></div>
              <div style="width: 100%;margin-bottom: 20px;">
                <div class="ContHolder">
                  {{entries.block_a_content_ar}}</div>
                              </div>
              <router-link v-bind:to="entries.block_link_ar" class="btn" v-bind:style="{color: entries.block_a_color }">{{entries.black_btn_name_ar}}</router-link>

            </div>
          </div>

         
        </splide-slide>
      </splide>
    </div>
    <div v-else-if="window.width > '600'">
      <splide :options="options">
        <splide-slide style="background-size: cover;"  v-for="entries in info.entries" :key="entries.src" v-bind:style="{ backgroundImage: 'url(' + baseurl + entries.block_a_image_tab.path + ')' }">
          <div id="SliderHolder_ar" class="container grid-xl">
            <div class="columns" v-bind:style="{color: entries.block_a_color }">

              <div style="width: 100%;"><h2 style="font-weight: 600;line-height: 1;">{{entries.block_a_title_ar_ar}}</h2></div>
              <div style="width: 100%;margin-bottom: 20px;">
                <div class="ContHolder">
                  {{entries.block_a_content_ar}}</div>
              </div>
              <router-link v-bind:to="entries.block_link_ar" class="btn" v-bind:style="{color: entries.block_a_color }">{{entries.black_btn_name_ar}}</router-link>

            </div>
          </div>
        </splide-slide>
      </splide>
    </div>
    <div v-else-if="window.width < '600'">
      <splide :options="options">
        <splide-slide style="background-size: cover;"  v-for="entries in info.entries" :key="entries.src" v-bind:style="{ backgroundImage: 'url(' + baseurl + entries.block_a_image_mobile.path + ')' }">
          <div id="SliderHolder_ar" class="container grid-xl">
            <div class="columns" v-bind:style="{color: entries.block_a_color }">

              <div style="width: 100%;"><h2 style="font-weight: 600;line-height: 1;">{{entries.block_a_title_ar_ar}}</h2></div>
              <div style="width: 100%;margin-bottom: 20px;">
                <div class="ContHolder">
                  {{entries.block_a_content_ar}}</div>
              </div>
              <router-link v-bind:to="entries.block_link_ar" class="btn" v-bind:style="{color: entries.block_a_color }">{{entries.black_btn_name_ar}}</router-link>

            </div>
          </div>
        </splide-slide>
      </splide>
    </div>

      </div>

    </section>


    <enfooter class="HFooter"></enfooter>
  </div>
</template>

<script>
// @ is an alias to /src
import enfooter from '@/components/enfooter.vue'
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import axios from 'axios'


export default {
  name: 'Home',
  components: {
    enfooter,
    Splide,
    SplideSlide,
  },


  data() {
    return {
      window: {
        width: 0,
        height: 0
      },

      info: null,
      loading: true,
      errored: false,
      baseurl: 'https://zubaircorp.com',

      options: {
        rewind : true,
        perPage: 1,
        direction: 'ttb',
        cover   : true,
        drag: true,
        autoplay: true,
        height   : '100vh',
        keyboard:true,
      },
    };

  },

  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  },

  mounted () {

    axios
            .get( this.baseurl + '/cms/api/collections/entries/Home')
            .then(response => {
              this.info = response.data
            })
            .catch(error => {
              console.log(error)
              this.errored = true
            })
            .finally(() => this.loading = false)



  },



}
</script>

<style>

ul.splide__pagination {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    align-content: stretch;
    flex-wrap: nowrap;
    flex-direction: row;
    left: 15px !important;
}

  .home {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
  }
  .home .container.grid-xl.HFooter div#FLeft div#nav-sec a {
    color: white !important;
  }
    .home .container.grid-xl.HFooter div#FLeft_ar div#nav-sec a {
    color: white !important;
  }
  .HFooter {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .splide__arrows {
    display: none;
  }
  .splide__pagination__page {
    width: 15px;
    height: 15px;
    background: #656565;
    margin: 10px;
  }
  .splide__pagination__page.is-active {
    transform: scale(1.8);
    background: #b1b1b1;
  }
  .splide__slide {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    color: white;
    background-position: center center;
    justify-content: center; /* Horizontal */
    background-color: #757575;


  }
  div#SliderHolder_ar {
    width: 85%;
    margin-left: 15%;
    margin-right: 0%;
    margin-top: 20%;
    text-shadow: 1px 1px 10px #000000;
    max-width: 960px;
  }
  .ContHolder
  {
    width: 50%;
  }


  @media only screen and (max-width: 840px)
  {
    div#SliderHolder_ar {
      margin-top: 60%;
    }
    .ContHolder
    {
      width: 75%;
    }
    
  }

  @media only screen and (max-width: 600px)
  {
    div#SliderHolder_ar {
      margin-top: 40%;
    }
    .HFooter {
      position: relative;
      color: black;
      padding-bottom: 0px;
      padding-top: 0px;
    }
    .ContHolder
    {
      width: 100%;
    }
    .splide--ttb>.splide__pagination {
      display: flex;
      flex-direction: column;
      bottom: 25%;
      left: auto;
      right: .5em;
      transform: translateY(50%);
    }

  }

@media only screen and (max-height: 500px)
  {
    div#SliderHolder_ar {
      margin-top: 20%;
    }
    .HFooter {
      position: relative;
      color: black;
      padding-bottom: 0px;
      padding-top: 0px;
    }
    .ContHolder
    {
      width: 100%;
    }
    .splide--ttb>.splide__pagination {
      display: flex;
      flex-direction: column;
      bottom: 25%;
      left: auto;
      right: .5em;
      transform: translateY(50%);
    }

  }

</style>
