<template>
  <div class="page">
    <section v-if="errored" style="height: 500px;display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
    background-color: #757575;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
    color: white;
	align-items: center;">
      <p style="width: 100%; text-align: center;">We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>

    <section v-else>
      <div v-if="loading">
        <div class="loading loading-lg" style="height: 100vh;display: block;position: relative;background-color: #757575;"></div>
      </div>

      <div v-if="info">



        <picture style="height: 500px;background-color: rgb(0 0 0);margin-bottom: 20px;
        background-position: center center;
        position: relative;display: block;">
          
          <div style="width: 90%;
    text-align: center;
    color: #ffffff;
    margin-left: 5%;
    margin-right: 5%;
    position: absolute;
    display: block;
    top: 50%;
    margin: auto;
    left: 0;
    right: 0;">
            <h1 style="margin-bottom: 0px;
    padding-top: 20px;
    font-weight: 700;
    line-height: 1;
    text-shadow: 5px 5px 20px #000000;">
              404
            </h1>
          </div>

        </picture>


        <div class="container grid-xl">
          <div class="columns">
            <div v-html="info.page_content"></div>

        <h1 style="width: 100%;
    text-align: center;">Page Not Found</h1>

          </div>
        </div>





      </div>

    </section>






    <enfooter></enfooter>

  </div>
</template>


<script>



  import enfooter from '@/components/enfooter.vue'
  import axios from 'axios'


  export default {

    name: 'search',
    data () {
      return {
        info: null,
        media: null,
        loading: true,
        errored: false,
        baseurl: 'https://zubaircorp.com',

        search:''


      }

    },


    components: {
      enfooter,

    },


    mounted () {

      axios
              .get( this.baseurl + '/cms/api/singletons/get/search?token=72d0a17a1cfa0d99e532c39232bdd5')
              .then(response => {
                this.info = response.data
              })
              .catch(error => {
                console.log(error)
                this.errored = true
              })
              .finally(() => this.loading = false)




    },




  }
</script>
