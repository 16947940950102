import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'



// register jw pagination component globally
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);


Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')



function showNotification() {
  const notification = new Notification ("The Zubair Corporation", {
    body:"Welcome to The Zubair Corporation Website",
    icon:"/img/icons/android-chrome-192x192.png"
  });
  notification.onclick=(e) => {
    window.location.href = "https://zubaircorp.com/"
  }
}

//console.log(Notification.permission);

if (Notification.permission === "granted") {
  //alert("we have permisstion");
  //showNotification();
} else if (Notification.permission !== "denied") {
  Notification.requestPermission().then(permission => {
    //console.log(permission)
    //showNotification();
  })
}


(function() {
  if ("Notification" in window) {
    var permission = Notification.permission;

    if (permission === "denied" || permission === "granted") {
      return;
    }

    Notification
      .requestPermission()
      .then(function() {
        showNotification();
      });
  }
})();

