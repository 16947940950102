<template>
  <div class="page">


    <section v-if="errored" style="height: 500px;display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
    background-color: #757575;
	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
    color: white;
	align-items: center;">
      <p style="width: 100%; text-align: center;">We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>

    <section v-else>
      <div v-if="loading">
        <div class="loading loading-lg" style="height: 100vh;display: block;position: relative;background-color: #757575;"></div>
      </div>

      <div v-if="info">



        <picture style="height: 500px;background-color: #757575;margin-bottom: 20px;
        background-position: center center;
        position: relative;display: block;">
          <source media="(min-width: 1280px)" v-bind:srcset="baseurl + info.page_placeholder_image_web.path">
          <source media="(min-width: 840px)" v-bind:srcset="baseurl + info.page_placeholder_image_tab.path">
          <source media="(min-width: 600px)" v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path">
          <img v-bind:src="baseurl + info.page_placeholder_image_mobile.path"
               v-bind:srcset="baseurl + info.page_placeholder_image_mobile.path"
               v-bind:data-srcset="baseurl + info.page_placeholder_image_mobile.path"
               v-bind:alt="info.page_title_ar"
               v-bind:title="info.page_title_ar"
               v-bind:aria-label="info.page_title_ar"
               style="width:100%;height: 500px;object-fit: cover;">

          <div style="width: 90%;
    text-align: center;
    color: #ffffff;
    margin-left: 5%;
    margin-right: 5%;
    position: absolute;
    display: block;
    top: 50%;
    margin: auto;
    left: 0;
    right: 0;">
            <h1 style="margin-bottom: 0px;
    padding-top: 20px;
    font-weight: 700;
    line-height: 1;
    text-shadow: 5px 5px 20px #000000;">
              {{ info.page_title_ar }}
            </h1>
          </div>

        </picture>


        <div class="container grid-xl">
          <div class="columns">


            <div style="margin-top: 30px;" v-html="info.page_content_ar"></div>


          </div>
        </div>






      </div>

    </section>







    <enfooter></enfooter>

  </div>

</template>

<script>

  import enfooter from '@/components/enfooter.vue'
  import axios from 'axios'

  export default {
    name: 'privacy-policy',

    data () {
      return {
        info: null,
        media: null,

        loading: true,
        errored: false,

        baseurl: 'https://zubaircorp.com'
      }
    },

    components: {
      enfooter
    },

    mounted () {

      axios
              .get( this.baseurl + '/cms/api/singletons/get/PrivacyPolicy?token=72d0a17a1cfa0d99e532c39232bdd5')
              .then(response => {
                this.info = response.data
              })
              .catch(error => {
                console.log(error)
                this.errored = true
              })
              .finally(() => this.loading = false)


    }


  }
</script>

<style>

</style>
>