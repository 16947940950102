<template>
    <div class="page">
        <h1>This is an our-mission-and-our-values page</h1>
        <enfooter></enfooter>

    </div>
</template>

<script>
    import enfooter from '@/components/enfooter.vue'

    export default {
        name: 'our-mission-and-our-values',
        components: {
            enfooter
        }
    }
</script>

