import Vue from "vue";
import VueRouter from "vue-router";

import home from "../views/en/home";
import home_ar from "../views/ar/home";

//import application from '../views/application'

import about from "../views/en/about";
import chairmansmessage from "../views/en/about/chairmans-message";
import Thezubairstory from "../views/en/about/the-zubair-story";
import ourorganisation from "../views/en/about/our-group";
import ourmissionourvalues from "../views/en/about/our-mission-and-our-values";

import theteam from "../views/en/about/the-team";
import member from "../views/en/about/the-team/id";

import about_ar from "../views/ar/about";
import chairmansmessage_ar from "../views/ar/about/chairmans-message";
import Thezubairstory_ar from "../views/ar/about/the-zubair-story";
import ourorganisation_ar from "../views/ar/about/our-group";
import ourmissionourvalues_ar from "../views/ar/about/our-mission-and-our-values";

import theteam_ar from "../views/ar/about/the-team";
import member_ar from "../views/ar/about/the-team/id";

import media from "../views/en/about/media";
import single from "../views/en/about/media/id";

import media_ar from "../views/ar/about/media";
import single_ar from "../views/ar/about/media/id";

import getintouch from "../views/en/about/get-in-touch";
import getintouch_ar from "../views/ar/about/get-in-touch";

import ourgroup from "../views/en/our-group";
import artsandheritage from "../views/en/our-organisation/arts-and-heritage";
import automotive from "../views/en/our-organisation/automotive";
import energyandlogistics from "../views/en/our-organisation/industrial-&-chemical-solutions";
import engineeringconstructingandcontracting from "../views/en/our-organisation/smart-electrification-&-automation";
import explorationandproduction from "../views/en/our-organisation/exploration-and-production";
import financialservices from "../views/en/our-organisation/financial-services";
import manufacturing from "../views/en/our-organisation/fast-moving-consumer-goods";
import realestateandhospitality from "../views/en/our-organisation/real-estate-development";
import zubairinvestment from "../views/en/our-organisation/zubair-investment";
import education from "../views/en/our-organisation/education";

import ourgroup_ar from "../views/ar/our-group";
import artsandheritage_ar from "../views/ar/our-organisation/arts-and-heritage";
import automotive_ar from "../views/ar/our-organisation/automotive";
import energyandlogistics_ar from "../views/ar/our-organisation/industrial-&-chemical-solutions";
import engineeringconstructingandcontracting_ar from "../views/ar/our-organisation/smart-electrification-&-automation";
import explorationandproduction_ar from "../views/ar/our-organisation/exploration-and-production";
import financialservices_ar from "../views/ar/our-organisation/financial-services";
import manufacturing_ar from "../views/ar/our-organisation/fast-moving-consumer-goods";
import realestateandhospitality_ar from "../views/ar/our-organisation/real-estate-development";
import zubairinvestment_ar from "../views/ar/our-organisation/zubair-investment";
import education_ar from "../views/ar/our-organisation/education";

import csr from "../views/en/csr";
import baitalzubairfoundation from "../views/en/csr/bait-al-zubair-foundation";
import zubairsec from "../views/en/csr/zubair-sec";
import otherinitiatives from "../views/en/csr/other-initiatives";

import csr_ar from "../views/ar/csr";
import baitalzubairfoundation_ar from "../views/ar/csr/bait-al-zubair-foundation";
import zubairsec_ar from "../views/ar/csr/zubair-sec";
import otherinitiatives_ar from "../views/ar/csr/other-initiatives";

import workwithus from "../views/en/work-with-us";
import notfound from "../views/en/notfound";
import privacypolicy from "../views/en/privacy-policy";
import termsandconditions from "../views/en/terms-and-conditions";

import workwithus_ar from "../views/ar/work-with-us";
import notfound_ar from "../views/ar/notfound";
import privacypolicy_ar from "../views/ar/privacy-policy";
import termsandconditions_ar from "../views/ar/terms-and-conditions";
// MAP
import map from "../views/map.vue";
//

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/en/",
  },
  {
    path: "/en/",
    name: "home",
    component: home,
  },
  {
    path: "/ar/",
    name: "home-ar",
    component: home_ar,
  },
  {
    path: "/en/about",
    name: "about",
    component: about,
    //active: true,
    redirect: "/en/about/chairmans-message",
  },
  {
    path: "/ar/about",
    name: "about-ar",
    component: about_ar,
    //active: true,
    redirect: "/ar/about/chairmans-message",
  },
  {
    path: "/en/about/chairmans-message",
    name: "chairmansmessage",
    component: chairmansmessage,
  },
  {
    path: "/ar/about/chairmans-message",
    name: "chairmansmessage-ar",
    component: chairmansmessage_ar,
  },
  {
    path: "/en/about/the-zubair-story",
    name: "Thezubairstory",
    component: Thezubairstory,
  },
  {
    path: "/ar/about/the-zubair-story",
    name: "Thezubairstory-ar",
    component: Thezubairstory_ar,
  },
  {
    path: "/en/about/our-group",
    name: "our-organisation",
    component: ourorganisation,
  },
  {
    path: "/ar/about/our-group",
    name: "our-organisation-ar",
    component: ourorganisation_ar,
  },
  {
    path: "/en/about/our-mission-and-our-values",
    name: "ourmissionourvalues",
    component: ourmissionourvalues,
  },
  {
    path: "/ar/about/our-mission-and-our-values",
    name: "ourmissionourvalues-ar",
    component: ourmissionourvalues_ar,
  },
  {
    path: "/en/about/the-team",
    name: "the-team",
    component: theteam,
  },
  {
    path: "/en/about/the-team/:memberid",
    name: "the-member",
    component: member,
  },
  {
    path: "/ar/about/the-team",
    name: "the-team-ar",
    component: theteam_ar,
  },
  {
    path: "/ar/about/the-team/:memberid",
    name: "the-member-ar",
    component: member_ar,
  },
  {
    path: "/en/about/media",
    name: "media",
    component: media,
  },
  {
    path: "/ar/about/media",
    name: "media-ar",
    component: media_ar,
  },
  {
    path: "/en/about/media/id/:mediaid",
    name: "mediaid",
    component: single,
  },
  {
    path: "/ar/about/media/id/:mediaid",
    name: "mediaid-ar",
    component: single_ar,
  },
  {
    path: "/en/about/get-in-touch",
    name: "get-in-touch",
    component: getintouch,
  },
  {
    path: "/ar/about/get-in-touch",
    name: "get-in-touch-ar",
    component: getintouch_ar,
  },
  {
    path: "/en/our-organisation",
    name: "our-group",
    component: ourgroup,
    redirect: "/en/our-organisation/mobility-&-equipment",
  },
  {
    path: "/ar/our-organisation",
    name: "our-group-ar",
    component: ourgroup_ar,
    redirect: "/ar/our-organisation/mobility-&-equipment",
  },
  // {
  //   path: "/en/our-organisation/arts-and-heritage",
  //   name: "arts-and-heritage",
  //   component: artsandheritage,
  // },
  // {
  //   path: "/ar/our-organisation/arts-and-heritage",
  //   name: "arts-and-heritage-ar",
  //   component: artsandheritage_ar,
  // },
  {
    path: "/en/our-organisation/mobility-&-equipment",
    name: "automotive",
    component: automotive,
  },
  {
    path: "/ar/our-organisation/mobility-&-equipment",
    name: "automotive-ar",
    component: automotive_ar,
  },
  {
    path: "/en/our-organisation/industrial-&-chemical-solutions",
    name: "industrial-&-chemical-solutions",
    component: energyandlogistics,
  },
  {
    path: "/ar/our-organisation/industrial-&-chemical-solutions",
    name: "industrial-&-chemical-solutions-ar",
    component: energyandlogistics_ar,
  },
  {
    path: "/en/our-organisation/smart-electrification-&-automation",
    name: "smart-electrification-&-automation",
    component: engineeringconstructingandcontracting,
  },
  {
    path: "/ar/our-organisation/smart-electrification-&-automation",
    name: "smart-electrification-&-automation-ar",
    component: engineeringconstructingandcontracting_ar,
  },
  {
    path: "/en/our-organisation/energy-&-natural-resources",
    name: "exploration-and-production",
    component: explorationandproduction,
  },
  {
    path: "/ar/our-organisation/energy-&-natural-resources",
    name: "exploration-and-production-ar",
    component: explorationandproduction_ar,
  },
  {
    path: "/en/our-organisation/digitisation-&-information-technology",
    name: "financial-services",
    component: financialservices,
  },
  {
    path: "/ar/our-organisation/digitisation-&-information-technology",
    name: "financial-services-ar",
    component: financialservices_ar,
  },
  {
    path: "/en/our-organisation/fast-moving-consumer-goods",
    name: "fast-moving-consumer-goods",
    component: manufacturing,
  },
  {
    path: "/ar/our-organisation/fast-moving-consumer-goods",
    name: "manufacturing-ar",
    component: manufacturing_ar,
  },
  {
    path: "/en/our-organisation/real-estate-development",
    name: "real-estate-development",
    component: realestateandhospitality,
  },
  {
    path: "/ar/our-organisation/real-estate-development",
    name: "real-estate-development-ar",
    component: realestateandhospitality_ar,
  },
  {
    path: "/en/our-organisation/zubair-investment",
    name: "zubair-investment",
    component: zubairinvestment,
  },
  {
    path: "/ar/our-organisation/zubair-investment",
    name: "zubair-investment-ar",
    component: zubairinvestment_ar,
  },
  {
    path: "/en/our-organisation/education",
    name: "education",
    component: education,
  },
  {
    path: "/ar/our-organisation/education",
    name: "education-ar",
    component: education_ar,
  },
  {
    path: "/en/csr",
    name: "csr",
    component: csr,
    //redirect: '/en/csr/bait-al-zubair-foundation',
  },
  {
    path: "/ar/csr",
    name: "csr-ar",
    component: csr_ar,
    //redirect: '/en/csr/bait-al-zubair-foundation',
  },
  {
    path: "/en/csr/bait-al-zubair-foundation",
    name: "bait-al-zubair-foundation",
    component: baitalzubairfoundation,
  },
  {
    path: "/ar/csr/bait-al-zubair-foundation",
    name: "bait-al-zubair-foundation-ar",
    component: baitalzubairfoundation_ar,
  },
  {
    path: "/en/csr/zubair-sec",
    name: "zubair-sec",
    component: zubairsec,
  },
  {
    path: "/ar/csr/zubair-sec",
    name: "zubair-sec-ar",
    component: zubairsec_ar,
  },
  {
    path: "/en/csr/other-initiatives",
    name: "other-initiatives",
    component: otherinitiatives,
  },
  {
    path: "/ar/csr/other-initiatives",
    name: "other-initiatives-ar",
    component: otherinitiatives_ar,
  },
  {
    path: "/en/work-with-us",
    name: "work-with-us",
    component: workwithus,
  },
  {
    path: "/ar/work-with-us",
    name: "work-with-us-ar",
    component: workwithus_ar,
  },
  {
    path: "/en/privacy-policy",
    name: "privacy-policy",
    component: privacypolicy,
  },
  {
    path: "/ar/privacy-policy",
    name: "privacy-policy-ar",
    component: privacypolicy_ar,
  },
  {
    path: "/en/terms-and-conditions",
    name: "terms-and-conditions",
    component: termsandconditions,
  },
  {
    path: "/ar/terms-and-conditions",
    name: "terms-and-conditions-ar",
    component: termsandconditions_ar,
  },
  // MAP
  {
    path: "/map",
    name: "map",
    component: map,
  },
  //
  {
    path: "/en/*",
    name: "notfound",
    component: notfound,
  },
  {
    path: "/ar/*",
    name: "notfound-ar",
    component: notfound_ar,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

  linkActiveClass: "page-active",
  linkExactActiveClass: "exact-active",

  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

export default router;
