<template>
  <div id="app">
    <div
      id="HeaderHolder"
      v-if="main"
      style="direction:ltr;font-family: 'Inter', sans-serif;text-align: left;"
    >
      <div
        class="container grid-xl"
        style="padding-left: 20px;padding-right: 20px;"
      >
        <div class="columns">
          <div
            class="column col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 centerme"
          >
            <router-link to="/en/" exact>
              <img
                id="MasterLogo"
                width="150"
                height="150"
                title="The Zubair Corporation"
                alt="The Zubair Corporation"
                aria-label="The Zubair Corporation"
                class="SiteLogo"
                src="./assets/logo_white.svg"
              />
            </router-link>

            <span
              class="show-lg float-right"
              style="top: 15px;position: relative;"
            >
              <div class="dropdown dropdown-right">
                <span id="MMenu" class="dropdown-toggle" tabindex="0">
                  <img
                    width="40px"
                    height="40px"
                    alt="Language"
                    class="MobileMenu"
                    src="./assets/menu.svg"
                  />
                </span>

                <!-- menu component -->
                <ul
                  id="MobileMenu"
                  class="menu"
                  :class="{ 'hidden-navbar': !showNavbar }"
                >
                  <div class="accordion">
                    <input
                      type="radio"
                      id="accordion-1"
                      name="accordion-radio"
                      hidden
                    />
                    <label
                      class="text-bold text-secondary text-large"
                      for="accordion-1"
                    >
                      About
                    </label>
                    <div class="accordion-body">
                      <div class="columns" style="max-width: 250px;">
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/about/chairmans-message"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Chairman's Message</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/about/the-zubair-story"
                            style="margin-left: 0px;margin-right: 0px;"
                            >The Zubair Story</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/about/our-group"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Our Group</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/about/the-team"
                            style="margin-left: 0px;margin-right: 0px;"
                            >The Team</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/about/media"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Media</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/about/get-in-touch"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Get In Touch</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion">
                    <input
                      type="radio"
                      id="accordion-2"
                      name="accordion-radio"
                      hidden
                    />
                    <label
                      class="text-bold text-secondary text-large"
                      for="accordion-2"
                    >
                      Our Organisation
                    </label>
                    <div class="accordion-body">
                      <div class="columns" style="max-width: 250px;">
                        <!-- <div class="column col-12 pt-1 pb-1">
                                        <router-link to="/en/our-organisation/arts-and-heritage" style="margin-left: 0px;margin-right: 0px;">Arts & Heritage</router-link>
                                    </div> -->
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/our-organisation/mobility-&-equipment"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Mobility & Equipment</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/our-organisation/industrial-&-chemical-solutions"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Industrial & Chemical Solutions</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/our-organisation/smart-electrification-&-automation"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Smart Electrification & Automation</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/our-organisation/energy-&-natural-resources"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Energy & Natural Resources</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/our-organisation/education"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Education</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/our-organisation/digitisation-&-information-technology"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Digitisation & Information Technology</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/our-organisation/fast-moving-consumer-goods"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Fast Moving Consumer Goods</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/our-organisation/real-estate-development"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Real Estate Development</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/en/our-organisation/zubair-investment"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Zubair Investments</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion">
                    <input
                      type="radio"
                      id="accordion-3"
                      name="accordion-radio"
                      hidden
                    />
                    <label
                      class="text-bold text-secondary text-large"
                      for="accordion-3"
                    >
                      <router-link
                        class="text-secondary"
                        to="/en/csr/"
                        style="margin-left: 0px;margin-right: 0px;"
                        >CSR & Sustainability</router-link
                      >
                    </label>
                  </div>

                  <!-- <router-link class="text-bold text-secondary text-large" to="/en/work-with-us" style="width: 100%;display: block;margin-bottom: 10px;margin-top: 7px;">Work With Us</router-link> -->

                  <a
                    class="text-bold text-secondary text-large"
                    style="width: 100%;display: block;margin-bottom: 10px;margin-top: 7px;"
                    href="https://jobs.zubaircorp.com/"
                    target="_blank"
                    alt="Work With Us"
                    >Work With Us
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 12V6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H12M8.11111 12H12M12 12V15.8889M12 12L5 19"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      /></svg
                  ></a>

                  <router-link
                    class="text-bold text-secondary text-large"
                    to="/en/privacy-policy"
                    style="width: 100%;display: block;margin-bottom: 10px;margin-top: 7px;"
                    >Privacy Policy</router-link
                  >
                  <router-link
                    class="text-bold text-secondary text-large"
                    to="/en/terms-and-conditions"
                    style="width: 100%;display: block;margin-bottom: 10px;"
                    >Terms & Conditions</router-link
                  >

                  <div
                    style="border-top: 1px solid #585858;
    padding-top: 10px;
    padding-bottom: 10px;"
                  >
                    <span
                      style="margin-left: 10px;cursor: nwse-resize;"
                      title="Search"
                      aria-label="Search"
                      @click="showModal = true"
                    >
                      <img
                        width="30px"
                        height="30px"
                        class="Search-mobile"
                        alt="Search"
                        src="./assets/search.svg"
                      />
                    </span>

                    <router-link
                      v-if="main"
                      to="/ar/"
                      style="font-family: 'Tajawal', sans-serif;float: right;
    top: 10px;
    position: relative;"
                      >العربية</router-link
                    >
                  </div>
                </ul>
              </div>
            </span>

            <div id="nav" class="hide-lg">
              <!-- dropdown button group -->
              <div class="dropdown">
                <div class="btn-group">
                  <router-link
                    class="dropdown-toggle"
                    tabindex="0"
                    to="/en/about"
                    >About</router-link
                  >
                  <ul class="menu">
                    <div
                      class="container grid-sm"
                      style="font-size: 14px;padding-left: 10px;padding-right: 10px;"
                    >
                      <div class="columns">
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/about/chairmans-message"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Chairman's Message</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/about/the-zubair-story"
                            style="margin-left: 0px;margin-right: 0px;"
                            >The Zubair Story</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/about/our-group"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Our Group</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/about/the-team"
                            style="margin-left: 0px;margin-right: 0px;"
                            >The Team</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/about/media"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Media</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/about/get-in-touch"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Get In Touch</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="dropdown">
                <div class="btn-group">
                  <router-link
                    class="dropdown-toggle"
                    tabindex="0"
                    to="/en/our-organisation/"
                    >Our Organisation</router-link
                  >
                  <ul class="menu">
                    <div
                      class="container grid-sm"
                      style="font-size: 14px;padding-left: 10px;padding-right: 10px;"
                    >
                      <div class="columns">
                        <!-- <div class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1">
                                        <router-link to="/en/our-organisation/arts-and-heritage" style="margin-left: 0px;margin-right: 0px;">Arts & Heritage</router-link>
                                    </div> -->
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/our-organisation/mobility-&-equipment"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Mobility & Equipment</router-link
                          >
                        </div>

                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/our-organisation/energy-&-natural-resources"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Energy & Natural Resources</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/our-organisation/education"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Education</router-link
                          >
                        </div>

                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/our-organisation/industrial-&-chemical-solutions"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Industrial & Chemical Solutions</router-link
                          >
                        </div>

                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/our-organisation/digitisation-&-information-technology"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Digitisation & Information Technology</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/our-organisation/fast-moving-consumer-goods"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Fast Moving Consumer Goods</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/our-organisation/real-estate-development"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Real Estate Development</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/our-organisation/zubair-investment"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Zubair Investments</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/en/our-organisation/smart-electrification-&-automation"
                            style="margin-left: 0px;margin-right: 0px;"
                            >Smart Electrification & Automation</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="dropdown">
                <div class="btn-group">
                  <router-link class="dropdown-toggle" tabindex="0" to="/en/csr"
                    >CSR & Sustainability</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="column col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 centerme"
            style="text-align: right;display: inline-grid;"
          >
            <div id="nav-sec" class="hide-lg" style="font-size: 14px;">
              <router-link
                v-if="main"
                to="/ar/"
                style="font-family: 'Tajawal', sans-serif;"
                >العربية</router-link
              >

              <!-- <router-link to="/en/work-with-us">Work With Us</router-link> -->

              <a
                style="font-weight: 500;"
                href="https://jobs.zubaircorp.com/"
                target="_blank"
                alt="Work With Us"
                >Work With Us
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12V6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H12M8.11111 12H12M12 12V15.8889M12 12L5 19"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  /></svg
              ></a>

              <span
                style="margin-left: 10px;cursor: nwse-resize;"
                title="Search"
                aria-label="Search"
                @click="showModal = true"
                ><img
                  width="20px"
                  height="20px"
                  class="Search"
                  alt="Search"
                  src="./assets/search.svg"
              /></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      id="HeaderHolder"
      v-if="!main"
      style="direction:rtl;font-family: 'Tajawal', sans-serif;text-align: right;"
    >
      <div
        class="container grid-xl"
        style="padding-left: 20px;padding-right: 20px;"
      >
        <div class="columns">
          <div
            class="column col-9 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 centerme"
          >
            <router-link to="/ar/" exact>
              <img
                id="MasterLogo"
                width="150"
                height="150"
                title="مؤسسة الزبير"
                alt="مؤسسة الزبير"
                aria-label="مؤسسة الزبير"
                class="SiteLogo_ar"
                src="./assets/logo_white.svg"
              />
            </router-link>

            <span
              class="show-lg float-left"
              style="top: 15px;position: relative;"
            >
              <div class="dropdown dropdown-right">
                <span id="MMenu" class="dropdown-toggle" tabindex="0">
                  <img
                    width="40px"
                    height="40px"
                    alt="Language"
                    class="MobileMenu"
                    src="./assets/menu.svg"
                  />
                </span>

                <!-- menu component -->
                <ul
                  id="MobileMenu"
                  class="menu"
                  :class="{ 'hidden-navbar': !showNavbar }"
                  style="left: 0;
    right: auto;min-width: 300px;"
                >
                  <div class="accordion">
                    <input
                      type="radio"
                      id="accordion-1"
                      name="accordion-radio"
                      hidden
                    />
                    <label
                      class="text-bold text-secondary text-large"
                      for="accordion-1"
                    >
                      من نحن
                    </label>
                    <div class="accordion-body">
                      <div class="columns" style="max-width: 250px;">
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/about/chairmans-message"
                            style="margin-left: 0px;margin-right: 0px;"
                            >رسالة رئيس مجلس الإدارة</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/about/the-zubair-story"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قصة الزبير</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/about/our-group"
                            style="margin-left: 0px;margin-right: 0px;"
                            >مجموعة الزبير</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/about/the-team"
                            style="margin-left: 0px;margin-right: 0px;"
                            >الفريق</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/about/media"
                            style="margin-left: 0px;margin-right: 0px;"
                            >الإعلام</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/about/get-in-touch"
                            style="margin-left: 0px;margin-right: 0px;"
                            >تواصل معنا</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion">
                    <input
                      type="radio"
                      id="accordion-2"
                      name="accordion-radio"
                      hidden
                    />
                    <label
                      class="text-bold text-secondary text-large"
                      for="accordion-2"
                    >
                      عن مجموعتنا
                    </label>
                    <div class="accordion-body">
                      <div class="columns" style="max-width: 250px;">
                        <!-- <div class="column col-12 pt-1 pb-1">
                                        <router-link to="/ar/our-organisation/arts-and-heritage" style="margin-left: 0px;margin-right: 0px;">الفن والتاريخ</router-link>
                                    </div> -->
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/our-organisation/mobility-&-equipment"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع النقل والمعدات</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/our-organisation/industrial-&-chemical-solutions"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع الحلول الصناعية والكيميائية
                          </router-link>
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/our-organisation/smart-electrification-&-automation"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع الكهرباء الذكية وأنظمة التشغيل
                            الآلي</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/our-organisation/energy-&-natural-resources"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع الطاقة والموارد الطبيعية</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/our-organisation/education"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع التعليم</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/our-organisation/digitisation-&-information-technology"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع تكنولوجيا المعلومات</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/our-organisation/fast-moving-consumer-goods"
                            style="margin-left: 0px;margin-right: 0px;"
                          >
                            قطاع السلع الإستهلاكية سريعة الحركة
                          </router-link>
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/our-organisation/real-estate-development"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع التطوير العقاري</router-link
                          >
                        </div>
                        <div class="column col-12 pt-1 pb-1">
                          <router-link
                            to="/ar/our-organisation/zubair-investment"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع الزبير للإستثمار</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion">
                    <input
                      type="radio"
                      id="accordion-3"
                      name="accordion-radio"
                      hidden
                    />
                    <label
                      class="text-bold text-secondary text-large"
                      for="accordion-3"
                    >
                      <router-link
                        class="text-secondary"
                        to="/ar/csr/"
                        style="margin-left: 0px;margin-right: 0px;"
                        >المسؤولية الاجتماعية للشركات والاستدامة</router-link
                      >
                    </label>
                  </div>

                  <!-- <router-link class="text-bold text-secondary text-large" to="/ar/work-with-us" style="width: 100%;display: block;margin-bottom: 10px;margin-top: 7px;">انضم إلى فريقنا</router-link> -->

                  <a
                    class="text-bold text-secondary text-large"
                    style="width: 100%;display: block;margin-bottom: 10px;margin-top: 7px;"
                    href="https://jobs.zubaircorp.com/"
                    target="_blank"
                    alt="انضم إلى فريقنا"
                    >انضم إلى فريقنا
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 12V6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H12M8.11111 12H12M12 12V15.8889M12 12L5 19"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      /></svg
                  ></a>

                  <router-link
                    class="text-bold text-secondary text-large"
                    to="/ar/privacy-policy"
                    style="width: 100%;display: block;margin-bottom: 10px;margin-top: 7px;"
                    >سياسة الخصوصية</router-link
                  >
                  <router-link
                    class="text-bold text-secondary text-large"
                    to="/ar/terms-and-conditions"
                    style="width: 100%;display: block;margin-bottom: 10px;"
                    >الشروط والأحكام</router-link
                  >

                  <div
                    style="border-top: 1px solid #585858;
    padding-top: 10px;
    padding-bottom: 10px;"
                  >
                    <span
                      style="margin-left: 10px;cursor: nwse-resize;"
                      title="Search"
                      aria-label="Search"
                      @click="showModal = true"
                    >
                      <img
                        width="30px"
                        height="30px"
                        class="Search-mobile"
                        alt="Search"
                        src="./assets/search.svg"
                      />
                    </span>

                    <router-link
                      v-if="!main"
                      to="/en/"
                      style="font-family: 'Inter', sans-serif;float: left;
    top: 10px;
    position: relative;"
                      >English</router-link
                    >
                  </div>
                </ul>
              </div>
            </span>

            <div id="nav" class="hide-lg">
              <!-- dropdown button group -->
              <div class="dropdown">
                <div class="btn-group">
                  <router-link
                    class="dropdown-toggle"
                    tabindex="0"
                    to="/ar/about"
                    >من نحن</router-link
                  >
                  <ul class="menu">
                    <div
                      class="container grid-sm"
                      style="font-size: 14px;padding-left: 10px;padding-right: 10px;"
                    >
                      <div class="columns">
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/about/chairmans-message"
                            style="margin-left: 0px;margin-right: 0px;"
                            >رسالة رئيس مجلس الإدارة</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/about/the-zubair-story"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قصة الزبير</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/about/our-group"
                            style="margin-left: 0px;margin-right: 0px;"
                            >مجموعة الزبير</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/about/the-team"
                            style="margin-left: 0px;margin-right: 0px;"
                            >الفريق</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/about/media"
                            style="margin-left: 0px;margin-right: 0px;"
                            >الإعلام</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/about/get-in-touch"
                            style="margin-left: 0px;margin-right: 0px;"
                            >تواصل معنا</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="dropdown">
                <div class="btn-group">
                  <router-link
                    class="dropdown-toggle"
                    tabindex="0"
                    to="/ar/our-organisation/"
                    >عن مجموعتنا</router-link
                  >
                  <ul class="menu">
                    <div
                      class="container grid-sm"
                      style="font-size: 14px;padding-left: 10px;padding-right: 10px;"
                    >
                      <div class="columns">
                        <!-- <div class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1">
                                        <router-link to="/ar/our-organisation/arts-and-heritage" style="margin-left: 0px;margin-right: 0px;">الفن والتاريخ</router-link>
                                    </div> -->
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/our-organisation/mobility-&-equipment"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع النقل والمعدات</router-link
                          >
                        </div>

                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/our-organisation/energy-&-natural-resources"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع الطاقة والموارد الطبيعية</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/our-organisation/education"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع التعليم</router-link
                          >
                        </div>

                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/our-organisation/industrial-&-chemical-solutions"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع الحلول الصناعية والكيميائية
                          </router-link>
                        </div>

                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/our-organisation/digitisation-&-information-technology"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع تكنولوجيا المعلومات</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/our-organisation/fast-moving-consumer-goods"
                            style="margin-left: 0px;margin-right: 0px;"
                          >
                            قطاع السلع الإستهلاكية سريعة الحركة
                          </router-link>
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/our-organisation/real-estate-development"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع التطوير العقاري</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/our-organisation/zubair-investment"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع الزبير للإستثمار</router-link
                          >
                        </div>
                        <div
                          class="column col-4 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-1 pb-1"
                        >
                          <router-link
                            to="/ar/our-organisation/smart-electrification-&-automation"
                            style="margin-left: 0px;margin-right: 0px;"
                            >قطاع الكهرباء الذكية وأنظمة التشغيل
                            الآلي</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="dropdown">
                <div class="btn-group">
                  <router-link class="dropdown-toggle" tabindex="0" to="/ar/csr"
                    >المسؤولية الاجتماعية للشركات والاستدامة</router-link
                  >
                </div>
              </div>
            </div>
          </div>

          <div
            class="column col-3 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 centerme"
            style="text-align: left;display: inline-grid;"
          >
            <div id="nav-sec" class="hide-lg" style="font-size: 14px;">
              <router-link
                v-if="!main"
                to="/en/"
                style="font-family: 'Inter', sans-serif;"
                >English</router-link
              >

              <!-- <router-link to="/ar/work-with-us">انضم إلى فريقنا</router-link> -->

              <a
                style="font-weight: 500;"
                href="https://jobs.zubaircorp.com/"
                target="_blank"
                alt="انضم إلى فريقنا"
                >انضم إلى فريقنا
                <svg
                  style=" -webkit-transform: scaleX(-1);
                    transform: scaleX(-1);"
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12V6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H12M8.11111 12H12M12 12V15.8889M12 12L5 19"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  /></svg
              ></a>

              <span
                style="margin-right: 10px;cursor: nwse-resize;"
                title="Search"
                aria-label="Search"
                @click="showModal = true"
                ><img
                  width="20px"
                  height="20px"
                  class="Search"
                  alt="Search"
                  src="./assets/search.svg"
              /></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <span
      v-if="main"
      style="direction:ltr;font-family: 'Inter', sans-serif;text-align: left;"
    >
      <router-view></router-view>
    </span>

    <span
      v-if="!main"
      style="direction:rtl;font-family: 'Tajawal', sans-serif;text-align: right;font-size: 18px;"
    >
      <router-view></router-view>
    </span>

    <div v-if="showModal" @close="showModal = false">
      <div class="modal active">
        <div class="modal-container" style="width: 95%;">
          <div class="modal-header"></div>
          <div class="modal-body" style="padding-left: 0;padding-right: 0;">
            <div class="content">
              <iframe
                v-if="main"
                v-bind:src="'google_search.html'"
                width="100%"
                height="300"
                frameborder="0"
              >
              </iframe>

              <iframe
                v-if="!main"
                v-bind:src="'ar_google_search.html'"
                width="100%"
                height="300"
                frameborder="0"
              >
              </iframe>
            </div>
          </div>

          <div
            v-if="main"
            style="text-align: right;margin-bottom: 30px;padding-right: 0px;padding-left: 0px;font-family: 'Inter', sans-serif;"
          >
            <button class="btn" @click="showModal = false">Close</button>
          </div>
          <div
            v-if="!main"
            style="text-align: left;margin-bottom: 30px;padding-right: 0px;padding-left: 0px;font-family: 'Tajawal', sans-serif;"
          >
            <button class="btn" @click="showModal = false">إغلاق</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const OFFSET = 10;

export default {
  data() {
    return {
      showModal: false,
      showNavbar: true,
      lastScrollPosition: 0,
      scrollValue: 0,
    };
  },

  computed: {
    main() {
      return this.$route.path.indexOf("/en") === 0;
    },
  },

  mounted() {
    this.lastScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", this.onScroll);
    const viewportMeta = document.createElement("meta");
    viewportMeta.name = "viewport";
    viewportMeta.content = "width=device-width, initial-scale=1";
    document.head.appendChild(viewportMeta);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    selectResult(book) {
      this.search = book.name;
      this.showModal = false;
    },

    onScroll() {
      if (window.pageYOffset < 0) {
        return;
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < OFFSET) {
        return;
      }
      this.showNavbar = window.pageYOffset < this.lastScrollPosition;
      this.lastScrollPosition = window.pageYOffset;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
@import url("./css/spectre.min.css");

ul#MobileMenu.hidden-navbar {
  display: none;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#HeaderHolder {
  z-index: 2;
  position: relative;
  padding-bottom: 40px;
  padding-top: 15px;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4150035014005602) 40%,
    rgba(0, 0, 0, 0.8715861344537815) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4150035014005602) 40%,
    rgba(0, 0, 0, 0.8715861344537815) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4150035014005602) 40%,
    rgba(0, 0, 0, 0.8715861344537815) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);

  transition-property: opacity;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  transition-delay: 100ms;
}

.results {
  text-align: center;
}
.result {
  margin: 5px;
  display: inline-block;
  padding: 5px;
  border: 1px solid gray;
  text-transform: capitalize;
  font-size: 14px;
}

.centerme {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
.modal {
  background-color: #00000080;
  padding: 20px;
}
img.SiteLogo {
  height: 150px;
  width: 150px;
  margin-right: 45px;
}
img.SiteLogo_ar {
  height: 150px;
  width: 150px;
  margin-left: 45px;
}
div#nav a {
  color: white;
  font-weight: 400;
  margin-left: 15px;
  margin-right: 15px;
}
div#nav-sec a {
  color: white;
  font-weight: 400;
  margin-left: 10px;
  margin-right: 10px;
}

div#nav a.exact-active.page-active {
  color: white;
  text-decoration: underline;
  margin-left: 15px;
  margin-right: 15px;
}

div#nav-sec a.exact-active.page-active {
  color: white;
  text-decoration: underline;
  margin-left: 10px;
  margin-right: 10px;
}
div#nav a.page-active {
  text-decoration: underline;
}
div#nav-sec a.page-active {
  text-decoration: underline;
}

img.MobileMenu {
  width: 40px;
  height: 40px;
}
span#MMenu:focus {
  outline: none;
}
ul#MobileMenu a {
  color: white;
}

img.Search {
  width: 20px;
  height: 20px;
  position: relative;
  top: 7px;
}
img.Search-mobile {
  width: 30px;
  height: 30px;
  position: relative;
  top: 7px;
}
div#nav-sec {
  display: inline;
}

@media only screen and (max-width: 960px) {
  .centerme {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
}
</style>
